import io from 'socket.io-client';

const token = localStorage.getItem('accessToken')
const socket = io("https://api-3taxi.gram.tj/admin_gram", {
  transports: ["websocket"],
  forceNew: true,
  auth: {
    token: token 
  }
});

export default socket